// colors
$color_decoration_main: rgba(#ed2e38, 1);
$color_black: rgba(#1a1a1e, 1);
$color_white: rgba(#ffffff, 1);
$color_darkgrey: rgba(#555555, 1);
$color_lightgrey: rgba(#efeff2, 1);

$color_facebook: rgba(#3b5998, 1);
$color_twitter: rgba(#55acee, 1);
$color_google: rgba(#dd4b39, 1);
$color_linkedin: rgba(#007bb5, 1);
$color_youtube: rgba(#bb0000, 1);
$color_instagram: rgba(#d6249f, 1);
$color_pinerest: rgba(#cb2027, 1);
$color_skype: rgba(#00aff0, 1);
$color_android: rgba(#a4c639, 1);
$color_dribbble: rgba(#ea4c89, 1);
$color_vimeo: rgba(#45bbff, 1);
$color_tumblr: rgba(#2c4762, 1);
$color_vine: rgba(#00b489, 1);
$color_foursquare: rgba(#45bbff, 1);
$color_stumbleupon: rgba(#eb4924, 1);
$color_flickr: rgba(#f40083, 1);
$color_yahoo: rgba(#430297, 1);
$color_soundcloud: rgba(#ff5500, 1);
$color_reddit: rgba(#ff5700, 1);
$color_rss: rgba(#ff6600, 1);

$color_text_accent: rgba(#444444, 1);
$color_text_accent_inverted: rgba(#ffffff, 1);

$color_text_paragraph: rgba(#555555, 1);
$color_text_paragraph_inverted: rgba(#ffffff, 1);

$color_text_link: rgba(#555555, 1);
$color_text_link_hover: rgba(#444444, 1);
$color_text_link_inverted: rgba(#ffffff, 1);
$color_text_link_hover_inverted: rgba(#ffffff, 1);

$color_divider: rgba(#d6d6de, 1);
$color_divider_inverted: rgba(#d6d6de, 1);
