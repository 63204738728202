* {
  outline: 0;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: initial;
  }
}
body {
  width: 100%;
  height: 100%;
  font-family: Helvetica, Arial, sans-serif;
  color: #555555;
  background-color: white;
}

html {
  width: 100%;
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 60px 0;
  font-weight: 700;
  letter-spacing: 1px;
}

h2 {
  font-size: 32px;
  color: #444444;
}

p {
  margin: 0 0 25px 0;
  font-size: 18px;
  line-height: 1.5;
}

p.paragraph--title {
  margin: 0 0 15px 0;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
}

p.footer-legal__copyright,
p.footer-legal__links {
  margin-bottom: 0;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
}

p.footer-legal__copyright {
  font-weight: normal;
}

@media (min-width: 768px) {
  p {
    margin: 0 0 35px 0;
    font-size: 20px;
    line-height: 1.6;
  }
}
@media (min-width: 992px) {
  p.contact--phone,
  p.contact--email,
  p.contact--location {
    margin-bottom: 0;
  }
}
a {
  color: #555555;
  transition: color 0.5s ease-in-out;
  text-decoration: underline;
}

a:hover,
a:focus {
  color: #444444;
}

/*p:not(.contact--email):not(.contact--social):not(.footer-legal__links):not(.footer-legal__copyright) a,
p:not(.contact--email):not(.contact--social) a:hover,
p:not(.contact--email):not(.contact--social) a:focus {
	text-decoration: underline;
}*/
p:not(.contact--social) a:hover,
p:not(.contact--social) a:focus {
  color: #444444;
}

/*
 * utilities
 */
.text-justify {
  text-align: justify !important;
}

/*
 * navbar
 */
.navbar-placeholder {
  position: relative;
  background-color: white;
  overflow: visible;
  opacity: 0.97;
  z-index: 1;
}

.navbar {
  padding: 20px 0;
  font-size: 12px;
  letter-spacing: 1px;
}

.navbar-brand {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  padding: 0;
  font-size: 16px;
}

.navbar-brand .svg-logo {
  display: block;
  margin-left: 10px;
  height: 30px;
  fill: rgba(0, 0, 0, 0.9);
}

.navbar-toggler,
.navbar-toggler:focus {
  padding: 0;
  border: 0;
  border-radius: 0;
  box-shadow: none;
}

.navbar-collapse .navbar-social {
  margin-left: -5px;
  display: none;
}

.navbar-collapse.collapsing .navbar-social,
.navbar-collapse.collapse.show .navbar-social {
  display: block;
}

@media (min-width: 992px) {
  .navbar-collapse.collapsing .navbar-social,
  .navbar-collapse.collapse.show .navbar-social {
    display: none;
  }
}
.navbar-collapse .navbar-social .fab {
  color: #555555;
  margin: 5px;
  padding: 15px;
  font-size: 20px;
  line-height: 20px;
  text-decoration: none;
  text-align: center;
  width: 20px;
  height: 20px;
  border: 1px solid #555555;
  border-radius: 50%;
  box-sizing: content-box;
}

.navbar-collapse .navbar-social .fab span {
  display: none;
}

.navbar-collapse .navbar-social .fab:hover,
.navbar-collapse .navbar-social .fab:focus {
  color: #444444;
  border-color: #444444;
}

/*
 * intro
 */
.intro {
  background-color: white;
  color: white;
  background-image: linear-gradient(to top, #555555, white);
}

.intro-image {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.75; /* default value, opacityDefault intro.js */
}

/*.intro-image::after {
	content: '';

	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;

	background-image: linear-gradient(45deg, #000000 33.33%, transparent 33.33%, transparent 50%, #000000 50%, #000000 83.33%, transparent 83.33%, transparent 100%);
	background-image: linear-gradient(45deg, transparent 0% 33.33%, #555 33.33% 50%, transparent 50% 83.33%, #555 83.33% 100%);
	background-size: 5px 5px;

	opacity: 0.5;
}*/
.intro-image__list {
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.intro-image__list-item {
  position: relative;
  flex-basis: 100%;
  flex-shrink: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: scroll;
  background-position: center top;
  list-style: none;
  width: 100%;
  height: 100%;
}

.intro-image-sliders__list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  list-style: none;
  margin: 0;
  padding: 0 15px;
  display: none;
}

@media (min-width: 992px) {
  .intro-image-sliders__list {
    display: flex;
    padding: 0 25px;
  }
}
.intro-image-sliders__list-item {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  font-size: 40px;
  color: white;
  -webkit-transition: background 0.3s ease-in-out, opacity 0.3s ease-in-out;
  -moz-transition: background 0.3s ease-in-out, opacity 0.3s ease-in-out;
  transition: background 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.intro-image-sliders__list-item:hover,
.intro-image-sliders__list-item:focus {
  cursor: pointer;
}

.intro-image-dots__list {
  display: flex;
  position: absolute;
  bottom: 15px;
  right: 15px;
  list-style: none;
  margin: 0;
  padding: 0;
  display: none;
}

@media (min-width: 768px) {
  .intro-image-dots__list {
    bottom: 25px;
    right: 25px;
  }
}
@media (min-width: 992px) {
  .intro-image-dots__list {
    display: flex;
  }
}
.intro-image-dots__list-item {
  width: 15px;
  height: 15px;
  margin-right: 5px;
  border: 2px solid white;
  border-radius: 50%;
}

.intro-image-dots__list-item:last-child {
  margin-right: 0;
}

.intro-image-dots__list-item--selected {
  background-color: white;
}

.intro .intro-body {
  padding-top: 100px;
  padding-bottom: 50px;
}

@media (min-width: 768px) {
  .intro .intro-body {
    padding-top: 100px;
    padding-bottom: 50px;
  }
}
.intro .intro-body {
  pointer-events: none;
}

.intro .intro-body a {
  pointer-events: auto;
}

.intro .intro-body .brand-heading-link,
.intro .intro-body .brand-heading-link:hover,
.intro .intro-body .brand-heading-link:focus {
  display: inline-block;
  text-decoration: none;
  color: white;
}

.intro .intro-body .svg-logo {
  width: 125px;
  fill: white;
}

@media (min-width: 768px) {
  .intro .intro-body .svg-logo {
    width: 125px;
  }
}
@media (min-width: 992px) {
  .intro .intro-body .svg-logo {
    width: 175px;
  }
}
.intro .intro-body .brand-heading {
  font-size: 40px;
  margin: 10px auto 0 auto;
}

@media (min-width: 768px) {
  .intro .intro-body .brand-heading {
    font-size: 50px;
  }
}
@media (min-width: 992px) {
  .intro .intro-body .brand-heading {
    font-size: 75px;
  }
}
/*
 * floor vizualization
 */
.floor {
  position: relative;
  width: 100%;
  background-color: white;
  background-image: linear-gradient(white, #efeff2);
}

.content-section {
  padding-top: 75px;
  padding-bottom: 50px;
}

/*
 * button down/up
 */
.btn-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  clear: both;
  margin: 25px auto;
  padding-top: 8px;
  width: 70px;
  height: 70px;
  font-size: 24px;
  background-color: transparent;
  color: #555555;
  border: 2px solid #555555;
  border-radius: 50%;
  -webkit-transition: background 0.3s ease-in-out;
  -moz-transition: background 0.3s ease-in-out;
  transition: background 0.3s ease-in-out;
}

.btn-circle.up {
  padding-top: 6px;
}

.btn-circle:hover,
.btn-circle:focus {
  background-color: rgba(255, 255, 255, 0.5);
  color: #444444;
  border-color: #444444;
}

.intro .btn-circle {
  background-color: rgba(128, 128, 128, 0.25);
  color: white;
  border-color: white;
}

.intro .btn-circle:hover,
.intro .btn-circle:focus {
  background-color: rgba(128, 128, 128, 0.5);
  color: white;
  border-color: white;
}

/*
 * Font Awesome icons
 */
.fa span,
.fas span {
  font-family: Helvetica, Arial, sans-serif;
  font-weight: 900;
}

/*
 * YT responsivity
 */
.videoWrapper {
  position: relative;
  /*padding-bottom: 56.25%;*/ /* 16:9 */
  padding-bottom: 62.5%; /* 16:10 */
  padding-top: 25px;
  padding-top: 0;
  height: 0;
}

.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/*
 * Footer
 */
footer {
  position: relative;
  padding: 50px 0;
  background-color: white;
}

footer .footer-legal__link {
  text-decoration: none;
}

footer .footer-legal__link:hover,
footer .footer-legal__link:focus {
  text-decoration: underline;
}

footer .footer-legal__link-separator {
  margin: 0 5px;
}

footer .svg-logo {
  display: inline-block;
  margin-bottom: 10px;
  height: 50px;
  fill: #555555;
}

footer .brand-heading-link:hover .svg-logo,
footer .brand-heading-link:focus .svg-logo {
  fill: #444444;
}

footer .brand-heading-link {
  display: inline-block;
}

footer .brand-heading-link,
footer .brand-heading-link:hover,
footer .brand-heading-link:focus {
  text-decoration: none;
}

/*
 * gallery
 */
.gallery {
  margin-bottom: 25px;
}

@media (min-width: 768px) {
  .gallery {
    margin-bottom: 35px;
  }
}
.gallery-tabs {
  margin-bottom: 25px;
}

@media (min-width: 768px) {
  .gallery-tabs {
    margin-bottom: 35px;
  }
}
.gallery-tabs__button-filter {
  position: relative;
  margin-bottom: 25px;
  padding: 8px 10px;
  background-color: white;
  border: 2px solid #555555;
  z-index: 0;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}

.gallery-tabs__button-filter::after {
  content: "";
  position: absolute;
  bottom: calc(50% - 7.5px);
  /*bottom: -9px;*/
  left: calc(50% - 7.5px);
  width: 15px;
  height: 15px;
  background-color: white;
  /*border-right: 2px solid $color_darkgrey;
  border-bottom: 2px solid $color_darkgrey;*/
  transform: rotate(45deg);
  z-index: -1;
  transition: all 0.3s ease-in-out;
}

.gallery-tabs__button-filter:hover,
.gallery-tabs__button-filter:focus,
.gallery-tabs__button-filter:hover::after,
.gallery-tabs__button-filter:focus::after {
  background-color: #efeff2;
  color: #444444;
  border-color: #444444;
  cursor: pointer;
}

.gallery-tabs__button-filter--active,
.gallery-tabs__button-filter--active::after {
  background-color: #555555;
  color: white;
  border-color: #555555;
}

.gallery-tabs__button-filter--active::after {
  bottom: -9px;
}

.gallery-tabs__button-filter--active:hover,
.gallery-tabs__button-filter--active:focus,
.gallery-tabs__button-filter--active:hover::after,
.gallery-tabs__button-filter--active:focus::after {
  background-color: #555555;
  color: white;
  border-color: #555555;
}

.gallery-tabs__list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /*justify-content: center;*/
  list-style: none;
  margin: 0;
  /*margin-top: 25px;*/
  padding: 0;
  font-size: 14px;
  text-transform: uppercase;
}

@media (min-width: 992px) {
  .gallery-tabs__list {
    justify-content: center;
  }
}
.gallery-tabs__list-item {
  flex-basis: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5px 10px;
  word-break: break-word;
}

.gallery-tabs-columns-1 .gallery-tabs__list-item {
  flex-basis: 100%;
}

.gallery-tabs-columns-2 .gallery-tabs__list-item {
  flex-basis: 50%;
}

.gallery-tabs-columns-3 .gallery-tabs__list-item {
  flex-basis: 33.3%;
}

.gallery-tabs-columns-4 .gallery-tabs__list-item {
  flex-basis: 25%;
}

.gallery-tabs-columns-5 .gallery-tabs__list-item {
  flex-basis: 20%;
}

@media (min-width: 992px) {
  .gallery-tabs__list-item,
  .gallery-tabs-columns-1 .gallery-tabs__list-item,
  .gallery-tabs-columns-2 .gallery-tabs__list-item,
  .gallery-tabs-columns-3 .gallery-tabs__list-item,
  .gallery-tabs-columns-4 .gallery-tabs__list-item,
  .gallery-tabs-columns-5 .gallery-tabs__list-item {
    flex-basis: unset;
  }
}
.gallery-tabs__list-item--enabled:hover,
.gallery-tabs__list-item--enabled:focus {
  text-decoration: underline;
  cursor: pointer;
}

.gallery-tabs__list-item-disabled {
  color: #ccc;
}

.gallery-tabs__list-item--active {
  background-color: #555555;
  color: white;
}

.gallery-tabs__list-item--active:hover,
.gallery-tabs__list-item--active:focus {
  text-decoration: none;
  /*cursor: auto;*/
  /*pointer-events: none;*/
}

.gallery__content {
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;
}

.gallery__list {
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 0;
  padding: 0;
  /* trik jak 2 itemy radku + jejich margin roztahnout na 100%
  ale ne pokud jich neni na radek plny pocet */
  flex-basis: 40%; /* toto neni polovina, ale vychozi velikost ktera se roztahne */
  flex-grow: 1; /* roztahne se pomoci grow do polovin */
  max-width: 48%; /* (fallback pro no-calc pro nasledujici radek) */
  max-width: calc(50% - 0.75em); /* ale ne uplne, tam kde je volne misto zafunguje tahle zarazka */
  /* proc - 0.75em? protoze je to 1.5 * 1 / 2 viz (*) */
  box-sizing: border-box;
  margin-right: 1.5em; /* (*) */
}

.gallery__list:nth-child(2n),
.gallery__list:last-child {
  margin-right: 0;
}

@media (min-width: 992px) {
  .gallery__list {
    flex-direction: row;
    flex-wrap: wrap;
  }
}
@media (min-width: 992px) {
  .gallery__list-item {
    /* trik jak 2 itemy radku + jejich margin roztahnout na 100%
    ale ne pokud jich neni na radek plny pocet */
    flex-basis: 40%; /* toto neni polovina, ale vychozi velikost ktera se roztahne */
    flex-grow: 1; /* roztahne se pomoci grow do polovin */
    max-width: 48%; /* (fallback pro no-calc pro nasledujici radek) */
    max-width: calc(50% - 0.75em); /* ale ne uplne, tam kde je volne misto zafunguje tahle zarazka */
    /* proc - 0.75em? protoze je to 1.5 * 1 / 2 viz (*) */
    box-sizing: border-box;
    margin-right: 1.5em; /* (*) */
  }
  .gallery__list-item:nth-child(2n) {
    margin-right: 0;
  }
}
.gallery__list-item-link {
  position: relative;
  display: block;
  margin-bottom: 1.5em;
  /* large frame */
  /*background-color: $color_white;
  padding: 15px;*/
  /* medium frame */
  /*background-color: $color_white;
  padding: 10px;*/
  /* small frame */
  /*background-color: $color_white;
  padding: 3px;*/
  /* no frame */
  background-color: #efeff2;
  padding: 1px;
  /*box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);*/
  box-shadow: 0px 2px 4px #efeff2;
}

.gallery__list-item-link:last-child {
  margin-bottom: 0;
}

/* inner: background */
/*.gallery__list-item-link::before {
	content: '';

	position: absolute;
	top: 3px;
	bottom: 3px;
	left: 3px;
	right: 3px;

	background-color: $color_lightgrey;
}*/
/* inner: shadow */
.gallery__list-item-link::after {
  content: "";
  position: absolute;
  /* large frame, medium frame, small frame */
  top: 3px;
  bottom: 3px;
  left: 3px;
  right: 3px;
  box-shadow: inset 0px 0px 4px #efeff2;
}

.gallery__list-item-link:hover,
.gallery__list-item-link:focus {
  outline: none;
}

.gallery__list-item-link:hover img,
.gallery__list-item-link:focus img {
  opacity: 0.75;
}

.gallery__list-item img {
  position: relative;
  display: block;
  width: 100%;
  webkit-transition: opacity 0.5s ease-in-out;
  -moz-transition: opacity 0.5s ease-in-out;
  transition: opacity 0.5s ease-in-out;
}

/*
 * contact
 */
.contact--email .fas,
.contact--location .fas,
.contact--phone .fas {
  margin-bottom: 10px;
  font-size: 25px;
}

@media (min-width: 768px) {
  .contact--email .fas,
  .contact--location .fas,
  .contact--phone .fas {
    font-size: 35px;
  }
}
.contact--email .fas span,
.contact--location .fas span,
.contact--phone .fas span {
  display: none;
}

.contact--social .fab {
  background-color: #555555;
  color: white;
  margin: 5px;
  padding: 15px;
  font-size: 20px;
  line-height: 20px;
  text-decoration: none;
  text-align: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  box-sizing: content-box;
}

.contact--social .fab span {
  display: none;
}

.contact--social .fa:hover,
.contact--social .fa:focus {
  color: white;
  /*opacity: 0.7;*/
}

.contact--social .fa-facebook-f:hover,
.contact--social .fa-facebook-f:focus {
  background: #3b5998;
}

.contact--social .fa-twitter:hover,
.contact--social .fa-twitter:focus {
  background: #55acee;
}

.contact--social .fa-google:hover,
.contact--social .fa-google:focus {
  background: #dd4b39;
}

.contact--social .fa-linkedin:hover,
.contact--social .fa-linkedin:focus {
  background: #007bb5;
}

.contact--social .fa-youtube:hover,
.contact--social .fa-youtube:focus {
  background: #bb0000;
}

.contact--social .fa-instagram:hover,
.contact--social .fa-instagram:focus {
  /*background: #125688;*/
  background: #d6249f;
  /*background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285aeb 90%);*/
}

.contact--social .fa-pinterest:hover,
.contact--social .fa-pinterest:focus {
  background: #cb2027;
}

.contact--social .fa-skype:hover,
.contact--social .fa-skype:focus {
  background: #00aff0;
}

.contact--social .fa-android:hover,
.contact--social .fa-android:focus {
  background: #a4c639;
}

.contact--social .fa-dribbble:hover,
.contact--social .fa-dribbble:focus {
  background: #ea4c89;
}

.contact--social .fa-vimeo:hover,
.contact--social .fa-vimeo:focus {
  background: #45bbff;
}

.contact--social .fa-tumblr:hover,
.contact--social .fa-tumblr:focus {
  background: #2c4762;
}

.contact--social .fa-vine:hover,
.contact--social .fa-vine:focus {
  background: #00b489;
}

.contact--social .fa-foursquare:hover,
.contact--social .fa-foursquare:focus {
  background: #45bbff;
}

.contact--social .fa-stumbleupon:hover,
.contact--social .fa-stumbleupon:focus {
  background: #eb4924;
}

.contact--social .fa-flickr:hover,
.contact--social .fa-flickr:focus {
  background: #f40083;
}

.contact--social .fa-yahoo:hover,
.contact--social .fa-yahoo:focus {
  background: #430297;
}

.contact--social .fa-soundcloud:hover,
.contact--social .fa-soundcloud:focus {
  background: #ff5500;
}

.contact--social .fa-reddit:hover,
.contact--social .fa-reddit:focus {
  background: #ff5700;
}

.contact--social .fa-rss:hover,
.contact--social .fa-rss:focus {
  background: #ff6600;
}

/*
 * warning
 */
.warning__layer {
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(85, 85, 85, 0.95);
  z-index: 99999; /* over all layers, bootstrap menu, fancybox, etc. */
}

.warning__window {
  position: relative;
  padding: 40px;
  background-color: white;
  color: #555555;
}

.warning__close {
  position: absolute;
  top: 15px;
  right: 15px;
}

.warning__close:hover,
.warning__close:focus {
  cursor: pointer;
}

.warning__close .fas {
  font-size: 25px;
}

.warning__close .fas span {
  display: none;
}

.warning__content .warning--description {
  margin-bottom: 0;
}

.warning__content .warning--info {
  margin-bottom: 0;
  font-size: 15px;
}

.warning__content .warning--ico .fas {
  font-size: 100px;
}

/*
 * Context menu mobile - download protection
 */
.gallery__list-item-link, .fancybox-image {
  -webkit-touch-callout: none;
}

/*
 * Clearfix
 */
.clearfix::before, .clearfix::after {
  content: "";
  display: table;
}
.clearfix::after {
  clear: both;
}

/*
 * Fixes
 */
.floor,
.footer {
  -webkit-backface-visibility: hidden;
}

/*==========  Mobile First Method  ==========*/
/* Custom, iPhone Retina */
/* Custom, iPhone Retina SE */
/* Custom, iPhone Retina 6 - 12 */
/* Extra Small Devices, Phones */
/* Small Devices, Tablets */
/* Medium Devices, Desktops */
/* Large Devices, Wide Screens */