// Link style
@mixin link-style ($color: $color_text_link, $decor: none, $color-hover: $color_text_link, $decor-hover: underline) {
	&:link, &:visited {
		color: $color;
		text-decoration: $decor;
	}
	&:hover, &:active {
		color: $color-hover;
		text-decoration: $decor-hover;
	}
	&:focus {
		outline: none;
	}
}

// Size 3:1 - 0.333
@mixin size3-1($width) {
	width: $width;
	height: ($width * 1)/3;
}

// Size 21:9 - 0.429
@mixin size21-9($width) {
	width: $width;
	height: ($width * 9)/21;
}

// Size 16:9 - 0.563
@mixin size16-9($width) {
	width: $width;
	height: ($width * 9)/16;
}

// Size 16:10 - 0.625
@mixin size16-10($width) {
	width: $width;
	height: ($width * 10)/16;
}

// Size 3:2 - 0.667
@mixin size3-2($width) {
	width: $width;
	height: ($width * 2)/3;
}

// Size 4:3 - 0.750
@mixin size4-3($width) {
	width: $width;
	height: ($width * 3)/4;
}

// Aspect ratio
@mixin aspect-ratio($width, $height) {
	position: relative;
	&:before {
		display: block;
		content: "";
		width: 100%;
		padding-top: ($height / $width) * 100%;
	}
}

// Text Alignment and Transformation classes
@mixin text-truncate() {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

@mixin text-hide() {
	font: 0/0 a;
	color: transparent;
	text-decoration: none;
}

// Clearfix
@mixin clearfix() {
	&::before,
	&::after {
		content: '';
		display: table;
	}
	&::after {
		clear: both;
	}
}

// Fix Safari border-radius bug
@mixin fixSafariBorderRadiusBug() {
	-webkit-mask-image: -webkit-radial-gradient(white, black);
}

// Line clamp
@mixin lineClamp($lines) {
	@if ($lines == 1) {
		white-space: nowrap;
		text-overflow: ellipsis;
	} @else {
		display: -webkit-box;
		-webkit-line-clamp: #{$lines};
		/*! autoprefixer: ignore next */
		-webkit-box-orient: vertical;
	}
	overflow: hidden;
}

// Typography
@function em($size, $base: 15) {
	@return ($size / $base)+em;
}
